// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.point {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  margin: 5% 10%;
}

.point-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 10%;
}


.animated-line {
  stroke-dasharray: 17;
  animation: dash 1s linear infinite;
}

.animated-line-reverse {
  stroke-dasharray: 17;
  animation: dash 1s linear infinite reverse;
}

@keyframes dash {
  to {
    stroke-dashoffset: 34;
  }
}
`, "",{"version":3,"sources":["webpack://./components/energyFlow/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;;AAGA;EACE,oBAAoB;EACpB,kCAAkC;AACpC;;AAEA;EACE,oBAAoB;EACpB,0CAA0C;AAC5C;;AAEA;EACE;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".point {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  z-index: 1;\n  margin: 5% 10%;\n}\n\n.point-holder {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  margin: 0 10%;\n}\n\n\n.animated-line {\n  stroke-dasharray: 17;\n  animation: dash 1s linear infinite;\n}\n\n.animated-line-reverse {\n  stroke-dasharray: 17;\n  animation: dash 1s linear infinite reverse;\n}\n\n@keyframes dash {\n  to {\n    stroke-dashoffset: 34;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
